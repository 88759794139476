<template>
  <div class="modal-card modal-form-group  rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('transfers') }}</div>
          <button @click="close" class="delete" aria-label="close"></button>
        </div>
        <section class="pa-40">
          <div class="columns is-flex is-align-items-center">
            <div class="column is-3">
              <div class="title-label">{{ $t('transfer_amount') }}</div>
            </div>
            <div class="column is-7">
              <div>
                <b-field custom-class="custom-field-label">
                  <b-input custom-class="" v-model="amount"
                           type="number"
                           placeholder="0"></b-input>
                </b-field>
              </div>
            </div>
            <div class="column is-2">
              <div>
                <b-dropdown
                    v-model="currency"
                    append-to-body
                    aria-role="menu"
                    scrollable
                    max-height="200"
                    trap-focus
                >
                  <template v-if="currency" #trigger>
                    <a
                        class="currency"
                        role="button">
                      <span>USD</span>
                      <b-icon icon="menu-down"></b-icon>
                    </a>
                  </template>

                  <template v-else #trigger>
                    <a
                        class="currency"
                        role="button">
                      <span>CUP</span>
                      <b-icon icon="menu-down"></b-icon>
                    </a>
                  </template>
                  <b-dropdown-item :value="true" aria-role="listitem">
                    <span>USD</span>
                  </b-dropdown-item>

                  <b-dropdown-item :value="false" aria-role="listitem">
                    <span>CUP</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <div class="columns is-flex is-align-items-center">
            <div class="column is-3">
              <div class="title-label">{{ $t('dealer_from') }}</div>
            </div>
            <div class="column is-9">
              <b-field>
                <multiselect
                    :placeholder="$t('issuer')"
                    v-model="fromDealer"
                    :custom-label="selectName"
                    open-direction="bottom"
                    :show-no-results="false"
                    :options="dealerList">
                </multiselect>
              </b-field>

            </div>
          </div>
          <div class="columns is-flex is-align-items-center">
            <div class="column is-3">
              <div class="title-label">{{ $t('dealer_to') }}</div>

            </div>
            <div class="column is-9">
              <b-field>
                <multiselect
                    :placeholder="$t('addressee')"
                    v-model="toDealer"
                    :custom-label="selectName"
                    open-direction="bottom"
                    :show-no-results="false"
                    :options="dealerList">
                </multiselect>
              </b-field>
            </div>
          </div>
          <div class="is-flex is-justify-content-flex-end">
            <b-button @click="save" class="btn btn-continue mx-10" type="is-primary">
              {{ $t('save') }}
            </b-button>
          </div>

        </section>
      </div>
    </section>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapActions} from "vuex";

export default {
  name: "modalsTransfersDealer",
  components: {Multiselect},
  data() {
    return {
      currency: false,
      amount: null,
      fromDealer: null,
      toDealer: null,
      dealerList: []
    }
  },
  methods: {
    ...mapActions({
      fetchDealer: 'FETCH_DEALER',
      saveTransfer: 'TRANSFER_DEALER'
    }),
    selectName({name}) {
      return name
    },
    save() {
      let request = {
        to: this.toDealer.id,
        from: this.fromDealer.id,
        amount: this.amount,
        currency: this.currency ? 'USD' : 'CUP'
      }
      this.saveTransfer(request)
          .then(() => {
            this.close()
          })
    },
    close() {
      this.$emit('close')
      this.$emit('reload')
    },
    getDealers(current = 1, record = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
      };
      return new Promise(resolve => {
        this.fetchDealer(data).then(value => resolve(value.data))

      })
    },
  },
  created() {
    this.getDealers()
        .then(value => {
          this.dealerList = value
        })
  }
}
</script>

<style scoped lang="scss">

.title-label {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #AEAEAE;
}

.currency {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #666BAA;
}

</style>