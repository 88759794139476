<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('add_expense') }}</div>
          <button @click="close" class="delete" aria-label="close"></button>
        </div>
        <section class="pl-20 pr-10 pt-15">
          <b-tabs type="is-boxed">
            <b-tab-item>
              <template #header>
                <span class="tag is-green-success width-110 mx-10 rounded-10">{{ $t('data') }}</span>
              </template>
              <div class="columns">
                <div class="column is-full">
                  <b-field :label="$t('amount2')">
                    <b-input v-model="amount" type="number" custom-class="full-input"></b-input>
                  </b-field>
                  <section>
                    <div>
                      <b-field :label="$t('description')">
                        <b-input v-model="razon" rounded maxlength="200" type="textarea"></b-input>
                      </b-field>
                    </div>
                  </section>
                </div>

              </div>
            </b-tab-item>
          </b-tabs>

          <div class="buttons mt-40 is-flex is-align-items-center is-justify-content-flex-end">
            <button @click="save" class="button save is-primary">{{ $t('save') }}</button>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {DEPOSIT_TYPE} from "@/enum/depositType";
import {CURRENCY} from "@/enum/currency";

export default {
  name: "modalAddBalanceExpenses",
  props: ['object', 'type', 'customer_type'],
  data() {
    return {
      currency: null,
      amount: null,
      razon: null,
      customersTypes: DEPOSIT_TYPE,
      balances: null,
    }
  },
  methods: {
    ...mapActions({
      saveBalance: 'DEPOSIT_BALANCE_EXPENSES'
    }),
    save() {
      let request = {
        id: this.object.id,
        amount: this.amount,
        razon: this.razon,
        currency: CURRENCY.CUP
      }
      this.saveBalance(request)
          .then(() => {
            this.close()
          })

    },
    close() {
      this.$emit('close')
      this.$emit('reload')
      this.amount = null
      this.razon = null
    }
  },
  created() {
  }
}
</script>

<style scoped lang="scss">
.title {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #666BAA;
}

.group {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #289BA4;
}
</style>